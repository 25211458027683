import fetch from 'node-fetch';
import { pushNotificationMessage } from './pushNotificationMessage';
import { siteUrl } from '../../config';

export async function sendNotifications(type, requestContent, userId, userType, lang) {
    let content = requestContent;

    if (type !== 'broadcastNotification') {
        const { title, message } = await pushNotificationMessage(type, requestContent, lang);
        content = { title, message, ...content }
    }

    // http://localhost:3000/push-notification
    const pushNotificationUrl = `${siteUrl}/push-notification`;
    const resp = await fetch(pushNotificationUrl, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            content,
            userId,
            userType
        }),
        credentials: 'include'
    });
    
    try {
        const { status, errorMessge } = await resp.json();
        return {
            status,
            errorMessge
        };
    } catch (error) {
        console.log("ERROR ON SEND PUSH NOTIFICATION:", error);
        return {
            status: 400,
            errorMessge: error
        };
    }
}

export default sendNotifications;
