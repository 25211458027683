import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import messages from '../../../../locale/messages';

import DriverIcon from '../../../../../public/Icons/doorstep-delivery.svg';
import TakeAwayIcon from '../../../../../public/Icons/take-away.svg';

export default function DeliveryMethodLabel({ order, formatMessage }) {

    return order.isDoorDelivery ?
        <OverlayTrigger
            key={order.id}
            placement="top"
            overlay={
                <Tooltip id={'tooltip-' + order.id}>
                    {formatMessage(messages.deliveryMethodDriverTooltip)}
                </Tooltip>
            }
        >
            <span className='text-danger'>
                <img src={DriverIcon} width={24} height={24} className='mr-1'/>
                ASAP Driver
            </span>
        </OverlayTrigger>
        :
        <OverlayTrigger
            key={order.id}
            placement="top"
            overlay={
                <Tooltip id={'tooltip-' + order.id}>
                    {formatMessage(messages.deliveryMethodSelfPickupTooltip)}
                </Tooltip>
            }
        >
            <span className='text-danger'>
                <img src={TakeAwayIcon} width={24} height={24} className='mr-1'/>
                {formatMessage(messages.selfPickup)}
            </span>
        </OverlayTrigger>;
}