import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './RestaurantHeader.css';
import { Navbar } from 'react-bootstrap';
import Link from '../../../Link';
import { connect } from 'react-redux'
import cx from 'classnames';
import { flowRight as compose } from 'lodash';
import RestaurantNavigation from '../RestaurantNavigation/RestaurantNavigation';
import LogoImage from '../../../../../public/SiteImages/R-logo.png';
import history from '../../../../history';
import { api, logoUploadDir } from '../../../../config';

class RestaurantHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
    };
    this.openMenu = this.openMenu.bind(this);
    this.openClose = this.openClose.bind(this);
  }


  async openMenu() {
    this.setState({
      isOpen: 1,
    })
  }
  async openClose() {
    this.setState({
      isOpen: 0,
    })
  }

  render() {
    let location;
    if (history.location) {
      location = history.location.pathname;
    }
    const { logo, logoHeight, logoWidth, siteName, isLogin, isOpen } = this.props

    return (
      <div className={cx(s.root,'mainMenu')}>
        <Navbar expand="lg" className={cx(s.navBg, isLogin ? 'restaurant' : '')}>
          <Navbar.Brand>
            <Link className={s.brand} to={isLogin ? "/restaurant/dashboard" : "/"}>
              <img
                src={api.apiEndpoint + logoUploadDir + logo}
                // srcSet={`${logoUrl2x} 2x`}
                // src={LogoImage}
                // height={34}
                // width={34}
                width={Number(logoWidth)}
                height={Number(logoHeight)}
                alt={siteName}
              />
            </Link>
          </Navbar.Brand>
          {!isLogin && <Navbar.Toggle aria-controls="basic-navbar-nav" className={cx(s.borderNone, s.outlineNone)}
            children={
              <div className={'menuToggle'} onClick={() => this.openMenu()}>
                {/* <input type="checkbox" /> */}
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
          />}
          <Navbar.Collapse id="basic-navbar-nav" className={cx({ [s.menuOpened]: this.state.isOpen == 1 },s.justifyFlexEnd, isLogin ? 'restaurant' : s.menuClosed)} in={isOpen}>
            <div onClick={() => this.openClose()}>
            <div className={cx(s.closeButton, 'closeButtonHeaderRTL')}> &#x2715; </div>
              <RestaurantNavigation />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

const mapState = (state) => ({
  logo: state.siteSettings.data.homeLogo,
  logoHeight: state.siteSettings.data.logoHeight,
  logoWidth: state.siteSettings.data.logoWidth,
  siteName: state.siteSettings.data.siteName,
  isLogin: state.runtime.isRestaurantAuthenticated
})
const mapDispatch = {

}

export default compose(withStyles(s), connect(mapState, mapDispatch))(RestaurantHeader)